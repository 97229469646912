<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="4">
              <b-form-group
                placeholder="Khoa/bộ môn"
              >
                <v-select
                  label="name"
                  v-model="filter.departmentId"
                  :options="departments"
                  :reduce="option => option.id"
                  placeholder="Khoa/bộ môn"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                placeholder="Năm"
              >
                <b-form-input
                  id="year"
                  v-model="filter.year"
                  name="year"
                  type="number"
                  placeholder="Năm"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                placeholder="Giảng viên"
              >
                <v-select
                  label="name"
                  v-model="filter.teacherId"
                  :options="teachers"
                  :reduce="option => option.id"
                  :disabled="checkTeacher"
                  placeholder="Giảng viên"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onSucceed()"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="SearchIcon" /> Tìm kiếm
                </span>
              </b-button>
              <b-button
                v-if="creatable"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.teacherQuotaSaveModal
                class="mx-1 mt-1 mt-md-0"
                variant="success"
                @click="onCreateTeacherQuota"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="PlusIcon" /> Thêm định mức giờ giảng
                </span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-1">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                :columns="columns"
                :rows="teacherQuotas"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.teacherQuotaSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditTeacherQuota(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteTeacher(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TeacherQuotaSave
      ref="saveModalRef"
      :item="currentTeacher"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import TeacherQuotaSave from '@/views/admin/teacherManagement/tw-teacher-quota/TwTeacherQuotaSave.vue'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'Teachers',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    vSelect,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    TeacherQuotaSave,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        departmentId: null,
        year: null,
        teacherId: null,
        status: 1,
        sort: null,
      },
      checkTeacher: true,
      currentTeacher: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên giảng viên',
          field: 'teacherName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/bộ môn',
          field: 'departmentName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Năm',
          field: 'year',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Định mức giờ giảng',
          field: 'quotaHours',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teacherQuotas: 'twTeacherQuota/teacherQuotas',
      totalRows: 'twTeacherQuota/totalRows',
      departments: 'twTeacherQuota/departments',
      teachers: 'twTeacherHour/teachers',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.TW_TEACHER_QUOTA)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TW_TEACHER_QUOTA)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TW_TEACHER_QUOTA)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getDepartments({
          currentPage: 1,
          itemsPerPage: 1000,
          status: 1,
          organizationId: this.user.orgId,
        }),
        this.readTeachers(),
        this.checkTeacher = this.user.teacherId !== null,
        this.getTeacherQuotasFromStore(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readTeacherQuotas: 'twTeacherQuota/readTeacherQuotas',
      deleteTeachers: 'twTeacherQuota/deleteTeacherQuotas',
      getDepartments: 'twTeacherQuota/getDepartments',
      readTeachers: 'twTeacherHour/getTeachers',
    }),
    onCreateTeacherQuota() {
      this.currentTeacher = undefined
    },
    onEditTeacherQuota(teacher) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = teacher
      this.currentTeacher = rest
    },
    onDeleteTeacher(teacher) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa định mức giờ giảng của giảng viên <span class="text-danger">${teacher.teacherName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTeachers(teacher.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readTeacherQuotas(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeacherQuotasFromStore()
    },
    async getTeacherQuotasFromStore() {
      this.isLoading = true
      try {
        this.filter.teacherId = this.user.teacherId
        await this.readTeacherQuotas(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getTeacherQuotasFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getTeacherQuotasFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'departmentId')) {
        this.updateParams({ departmentId: columnFilters.departmentId })
      }
      await this.getTeacherQuotasFromStore()
    },
    async onSucceed() {
      await this.getTeacherQuotasFromStore()
    },
  },
}
</script>
